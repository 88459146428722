@import url('https://fonts.googleapis.com/css2?family=Khula:wght@400;600;700&display=swap');
.App {
  /* text-align: center; */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

.fbxPlayer canvas{
  width: 100% !important;
  height: 100% !important;
  display: block;
}
.fbxPlayer{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.overlay, .overlay-label {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.overlay.show ,.overlay-label.show {
  opacity: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* .gallery img{
  max-width: 300px;
} */
.upload img{
  max-width: 60%;
  padding:20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* All related to stripe */
.stripe {
  /* text-align: center; */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stripe form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripe button {
  background: #5F8DF7;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.stripe button:hover {
  filter: contrast(115%);
}

.stripe button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .stripe form {
    width: 75vw;
    min-width: initial;
  }
}
/* END of stripe */
/* Carousel */
#case-studies-wrapper .carousel.carousel-slider{
overflow:visible;
}
#case-studies-wrapper .carousel.carousel-slider .control-arrow {
  color:#333;
  background-color: transparent;
}
#case-studies-wrapper .carousel .control-dots{
  bottom:-50px;
}
#case-studies-wrapper .carousel.carousel-slider .control-arrow:hover {
  background-color: #FFFFFF88; 
  color: #333;
}
#case-studies-wrapper .carousel .control-next.control-arrow:before {
  border-left: 8px solid #333;
}
#case-studies-wrapper .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #333;
}
#case-studies-wrapper .carousel .control-dots .dot{
  background-color: #6434E4;
  box-shadow: none;
}
.carousel .slide img{
  border-radius: 10px !important;
}
.second-image{
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  transition: clip-path 1.5s ease-out;
  transition-delay:1s;
}
.second-image:hover{
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  transition: clip-path 1.2s ease-in;
  
}
.second-image:hover img{
filter:grayscale();
}
.second-image.show {

  clip-path: polygon(100% 0%, 100% 0%,100% 100%, 100% 100%);
}

.changing-word {
  display: none;
  position: relative;
  /* bottom: -8px; */
  left:0px;
  opacity: 0;
  animation: wordOut .2s linear;
  /* font-style: italic; */
  font-weight: 700;

}

.changing-word.visible {
  display: block;
  opacity: 1;
  animation: wordIn .6s ease-out;
}

@keyframes wordIn {
  0% { opacity: 0; transform: translateY(-100%); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes wordOut {
  0% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(100%); }
}


@media only screen and (max-width: 600px) {
  #case-studies-wrapper .carousel.carousel-slider .control-arrow{
    display: none;
  }
}